import React, { useContext, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import './ContactModal.css'
import '../../../assets/css/modal.css'
import contactImg from '../../../assets/img/v2/contactVis.png'
import { dataContext } from '../../../App';
import activityLogSubmission from '../../../helper/activitylog';
import analyticEvent from '../../../helper/gaEvent';
const ContactModal = ({ showContactModal, setShowContactModal }) => {
  const { token, setToken, playerData, setPlayerData } = useContext(dataContext);

  function hideModalOperation() {
    setShowContactModal(false)
  }

  useEffect(() => {
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("contacts", msisdn);
    analyticEvent('contact', 'contact-view')
  }, [])


  return (
    <>
      <Modal show={showContactModal} centered dialogClassName="modal-dialog" contentClassName="modalContent" onHide={hideModalOperation}>
        <Modal.Header closeButton >
          <Modal.Title>যোগাযোগ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={contactImg} alt="" srcSet="" className="contactVis" />
          <div className="contactEmail"><i className="fa-solid fa-envelope"></i> <a href="mailto:support@quizard.com">support@quizard.com</a></div>
          <div className="contactMobile"><i className="fa-solid fa-phone"></i> <a href="tel:+8801988117755">+8801988117755</a></div>
          <div className="socialIconBox">
            
            <a href="https://www.facebook.com/quizard2024/"><i class="fa-brands fa-facebook"></i></a>
            <a href="https://www.messenger.com/t/quizard2024" target="_blank"><i class="fa-brands fa-facebook-messenger"></i></a>
            <a href="whatsapp://send?phone=+8801988117755" target="_blank"><i class="fa-brands fa-whatsapp"></i></a>
            <a href="https://www.instagram.com/quizard00/"><i className="fa-brands fa-instagram"></i></a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ContactModal