import React, { useContext } from 'react'
import { dataContext } from '../../App'
import styles from './Bottombar.module.css'
import homeImg from './bottomBar/bottomBarHome.png'
import leaderImg from './bottomBar/bottomBarLeaderboard.png'
import profileImg from './bottomBar/bottomBarUser.png'
import winnerImg from './bottomBar/bottomBarWinner.png'
import { HiHome, HiTrophy, HiUser } from "react-icons/hi2";

const Bottombar = ({ activeBottom, setActiveBottom, setShowLoginModal }) => {
  const { playerData } = useContext(dataContext);
  let imgSrc;
  if (activeBottom === 'home') { imgSrc = homeImg }
  if (activeBottom === 'leader') { imgSrc = leaderImg }
  if (activeBottom === 'profile') { imgSrc = profileImg }
  if (activeBottom === 'winner') { imgSrc = winnerImg }
  let activeStyles = {
    backgroundColor: "#f5f5f5"
  }

  return (
    <div className={styles.ppm_bottom_bar}>
      <div className={styles.bottom_nav}>
        {/* <img src={imgSrc} className={styles.bottomImg} alt="" srcSet="" /> */}
        <div className={styles.profileClick} style={(activeBottom==='profile')?activeStyles:{}} onClick={() => { (playerData?.id) ? (setActiveBottom('profile')) : setShowLoginModal(true); }}><HiUser /></div>
        <div className={styles.homeClick} style={(activeBottom==='home')?activeStyles:{}} onClick={() => { setActiveBottom('home'); }}><HiHome /></div>
        <div className={styles.leaderboardClick} style={(activeBottom==='leader')?activeStyles:{}} onClick={() => { setActiveBottom('leader'); }}><HiTrophy /></div>
        {/* <div className={styles.winnerClick} onClick={() => { setActiveBottom('winner'); }}></div> */}
      </div>
    </div>
  )
}

export default Bottombar