import React from 'react'
import styles from './Navbar.module.css'
import logoImg from './logo.png'
import menuImg from './menu.png'
const Navbar = ({ setShowSidebar }) => {

  return (
    <div className={styles.ppm_navbar}>
      <img src={menuImg} className={styles.toggleBtn} onClick={() => { setShowSidebar(true) }} alt="" srcSet="" />
      {/* <img src={logoImg} className={styles.ppm_logo} alt="" srcSet="" /> */}
    </div>
  )
}

export default Navbar