import React, { useContext, useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import './RewardsModal.css'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import reward1 from '../../../assets/rewards/1.png'
import reward2 from '../../../assets/rewards/2.png'
import reward3 from '../../../assets/rewards/3.png'

// import './App.css';

// import required modules
import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';
import { dataContext } from '../../../App';
import activityLogSubmission from '../../../helper/activitylog';
import analyticEvent from '../../../helper/gaEvent';

const RewardsModal = ({ showRewardsModal, setShowRewardsModal }) => {
  const {token, setToken, playerData, setPlayerData} = useContext(dataContext);
  function hideModalOperation() {
    setShowRewardsModal(false)
  }
  useEffect(() => {
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("rewards", msisdn);
    analyticEvent('rewards', 'rewards-view')
  }, [])
  return (
    <>
      <Modal show={showRewardsModal} centered dialogClassName="modal-dialog" contentClassName="rewardModalContent" onHide={hideModalOperation} >
        <Modal.Header closeButton  bsPrefix="rewardHeader">
          {/* <Modal.Title>পুরষ্কার</Modal.Title> */}
        </Modal.Header>
        <Modal.Body bsPrefix="rewardModalBody">
          {/* <div className="rewardIcon"><i className="fa-solid fa-gift"></i></div>
<p style={{textAlign:'center',fontSize:'22px',padding:'5px'}}>কুইজ প্রতিযোগিতায় অংশ গ্রহন করুন পুরস্কার জিতুন</p> */}

<div className='modalBodyCustom'>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 30,
          stretch: 0,
          depth: 200,
          modifier: 4,
          slideShadows: false,
        }}
        loop={true}
        // autoplay={{
        //   delay: 2500,
        //   disableOnInteraction: false,
        // }}
        pagination={false}
        modules={[Autoplay,EffectCoverflow, Pagination]}
        className="mySwiper"
      >

        <SwiperSlide className='rewardSwiperSlide'>
        <img src={reward2} />
        </SwiperSlide>
        <SwiperSlide  className='rewardSwiperSlide'>
        <img src={reward3} />
        </SwiperSlide>
        <SwiperSlide  className='rewardSwiperSlide'>
        <img src={reward1} />
        </SwiperSlide>
        <SwiperSlide  className='rewardSwiperSlide'>
        <img src={reward2} />
        </SwiperSlide>
        <SwiperSlide  className='rewardSwiperSlide'>
         <img src={reward3} />
        </SwiperSlide>
        <SwiperSlide  className='rewardSwiperSlide'>
          <img src={reward1} />
        </SwiperSlide>
      </Swiper>
    </div>

        </Modal.Body>
      </Modal>
    </>
  )
}

export default RewardsModal