import React, { useContext, useEffect, useState } from 'react'
import './QuizPage.css'
import backBtn from '../../assets/img/v2/back.png'
import { baseUrl, dataContext, portal } from '../../App';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import QuestionBody from './QuestionBody/QuestionBody';
import BlockRound from './BlockRound/BlockRound';
import ResultModal from './ResultModal/ResultModal';
import mainImg from "../../assets/img/mainBg.png";
import bplImg from "../../assets/img/background/quizpageMainBg.jpg";
import eidImg from "../../assets/img/background/eid.png";
// import boishakhImg from "../../assets/img/background/boishakh.png";
import weekImg from "../../assets/img/background/week.jpg";
import ramadanImg from "../../assets/img/background/ramadan.png";
import summerImg from "./bgimage/summerbg.png";
import kurbaniImg from "./bgimage/kurbani.jpg";
import worldcupImg from "./bgimage/worldcup.png";
import mixUpImg from "./bgimage/mixUpBg.png";
import independenceImg from "../../assets/img/background/independence.png";
import QuestionBodyBPL from './QuestionBodyBPL/QuestionBodyBPL';
import QuestionBodyEid from './QuestionBodyEid/QuestionBodyEid';
import QuestionBodyBoishakh from './QuestionBodyBoishakh/QuestionBodyBoishakh';
import QuestionBodyWeekly from './QuestionBodyWeekly/QuestionBodyWeekly';
import QuestionBodyInd from './QuestionBodyInd/QuestionBodyInd';
import QuestionBodySummer from './QuestionBodySummer/QuestionBodySummer';
import QuestionBodyRamadan from './QuestionBodyRamadan/QuestionBodyRamadan';
const QuizPage = () => {
  const navigate = useNavigate()
  const { token, setToken, playerData, setPlayerData } = useContext(dataContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = parseInt(searchParams.get("eventId"))
  const [eventDetails, setEventDetails] = useState({})
  const [showQuestions, setShowQuestions] = useState(false)
  const [showBlockModal, setShowBlockModal] = useState(false)
  const [showResultModal, setShowResultModal] = useState(false)
  const [finalScore, setFinalScore] = useState(0)
  const [playerRound, setPlayerRound] = useState(null)
  const [isLoadingEnd, setIsLoadingEnd] = useState(false)
  const [roundChecked, setRoundChecked] = useState(false)
  const [fetchRequest, setFetchRequest] = useState(false)

  useEffect(() => {
    // setEventDetails({
    //   "id": 62,
    //   "category": [
    //     "1",
    //     "4",
    //     "5",
    //     "6",
    //     "7",
    //     "8",
    //     "9",
    //     "10"
    //   ],
    //   "is_event": true,
    //   "weightage": "['12', '13', '14', '15', '16']",
    //   "event_type": "Daily",
    //   "events": "কুইজ প্রতিদিন",
    //   "round": 30,
    //   "question_set": "30",
    //   "passing_score": 15,
    //   "allocated_time": 120,
    //   "banner_url": "https://ms.purplepatch.online/bkash-quiz-banner/bl_banner/card1.png",
    //   "status": "active",
    //   "created_at": "2024-02-20T12:24:27.865284",
    //   "updated_at": "2024-02-21T11:14:40.918136",
    //   "company": 15,
    //   "user": 169
    // })
    if (token && eventId) {
      axios.get(`${baseUrl}/api/clients/participant_profiles/?portal=${portal}`, {
        headers: { Authorization: `Token ${token}` }
      })
        .then(response => {
          setPlayerData(response.data[0])
          let data = response.data[0];
          // console.log(data);
          const config = {
            headers: { Authorization: `Token ${token}` },
          };
          if (!data?.isSubscribe) {
            navigate('../')
          }else{
            axios.get(`${baseUrl}/api/rules/?portal=${portal}`,config)
            .then(response => {
              const allEvent = response.data || [];
              let eventDe = allEvent.filter(event => {
                return (event?.id === eventId)
              })
              if(!eventDe.length){
                navigate('../')
              }else{
                setEventDetails(eventDe[0])
                setIsLoadingEnd(true)
              }
            })
          }
        }).catch(res => {
          // console.log(res.response.status === 401)
          sessionStorage.setItem("AuthToken", "");
          sessionStorage.setItem("user_msisdn", "");
          setPlayerData({});
          // setShowLoginModal(true);
          setToken(null);
          navigate('../')
        });
    }else{
      navigate('../')
    }

  }, [])
  useEffect(() => {
    if (isLoadingEnd) {
      let playerLeaderboardArr = playerData?.event_history;
      let playerRoundNum = 0;
      let playerLeaderboard = []
      if (playerLeaderboardArr) { playerLeaderboard = playerLeaderboardArr.filter((a) => { if (a.event_id == eventDetails.id) { return a } }); }
      playerRoundNum = (playerLeaderboard.length) ? playerLeaderboard[0]?.round_number__max : 0;
      let eventRound = eventDetails?.round
      setPlayerRound(playerRoundNum)
      // console.log(playerRoundNum,eventRound,eventDetails)
      if (playerRoundNum < eventRound) {
        setShowQuestions(true)
      } else {
        setShowBlockModal(true)
      }
    }
  }, [isLoadingEnd])
  // CustomEvents Background and Element
  let backgroundImg;
  if (eventId === 32) { backgroundImg = bplImg }
  // else if(eventId === 36){backgroundImg = weekImg}
  else if (eventId === 37) { backgroundImg = independenceImg }
  // else if(eventId === 38){backgroundImg = ramadanImg}
  else if (eventId === 39) { backgroundImg = bplImg }
  else if (eventId === 53) { backgroundImg = worldcupImg }
  else if (eventId === 62) { backgroundImg = mixUpImg }
  // else if (eventId === 62) { backgroundImg = summerImg }
  else if (eventId === 55) { backgroundImg = kurbaniImg }
  else { backgroundImg = mixUpImg }
  let customEvent = [32, 37, 38, 39, 40, 53, 62, 55, 34];

  return (
    <div className='quizPageBody' style={{ backgroundImage: `url(${backgroundImg})` }}>
      {/* {(showQuestions && (eventId === 53)) && (<QuestionBodyBPL eventDetails={eventDetails} finalScore={finalScore} setFinalScore={setFinalScore} setShowResultModal={setShowResultModal} playerRound={playerRound}></QuestionBodyBPL>)} */}
      {/* {(showQuestions && (eventId === 14)) && (<QuestionBodySummer eventDetails={eventDetails} finalScore={finalScore} setFinalScore={setFinalScore} setShowResultModal={setShowResultModal} playerRound={playerRound}></QuestionBodySummer>)}
      {(showQuestions && (eventId === 37)) && (<QuestionBodyInd eventDetails={eventDetails} finalScore={finalScore} setFinalScore={setFinalScore} setShowResultModal={setShowResultModal} playerRound={playerRound}></QuestionBodyInd>)}
      {(showQuestions && (eventId === 53)) && (<QuestionBodyRamadan eventDetails={eventDetails} finalScore={finalScore} setFinalScore={setFinalScore} setShowResultModal={setShowResultModal} playerRound={playerRound}></QuestionBodyRamadan>)}
      {(showQuestions && (eventId === 55)) && (<QuestionBodyEid eventDetails={eventDetails} finalScore={finalScore} setFinalScore={setFinalScore} setShowResultModal={setShowResultModal} playerRound={playerRound}></QuestionBodyEid>)}
      {(showQuestions && (eventId === 62)) && (<QuestionBodyBoishakh eventDetails={eventDetails} finalScore={finalScore} setFinalScore={setFinalScore} setShowResultModal={setShowResultModal} playerRound={playerRound}></QuestionBodyBoishakh>)} */}
      {(showQuestions) && (<QuestionBodyBoishakh eventDetails={eventDetails} finalScore={finalScore} setFinalScore={setFinalScore} setShowResultModal={setShowResultModal} playerRound={playerRound}></QuestionBodyBoishakh>)}
      {/* {(showQuestions && (eventId === 36)) && (<QuestionBodyWeekly eventDetails={eventDetails} finalScore={finalScore} setFinalScore={setFinalScore} setShowResultModal={setShowResultModal} playerRound={playerRound}></QuestionBodyWeekly>)} */}

      {/* {(showQuestions && (!customEvent.includes(eventId))) && (<QuestionBody eventDetails={eventDetails} finalScore={finalScore} setFinalScore={setFinalScore} setShowResultModal={setShowResultModal} playerRound={playerRound}></QuestionBody>)} */}
      {showBlockModal && (<BlockRound eventDetails={eventDetails} showBlockModal={showBlockModal} setShowBlockModal={setShowBlockModal}></BlockRound>)}
      {showResultModal && (<ResultModal eventDetails={eventDetails} showResultModal={showResultModal} setShowResultModal={setShowResultModal} finalScore={finalScore}></ResultModal>)}

    </div>
  )
}

export default QuizPage