import React, { useContext, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import './ErrorSubscriptionModal.css'
import '../../../assets/css/modal.css'
import { dataContext } from '../../../App';
import activityLogSubmission from '../../../helper/activitylog';
import analyticEvent from '../../../helper/gaEvent';
const ErrorSubscriptionModal = ({ showErrorSubscriptionModal, setShowErrorSubscriptionModal }) => {
  const { token, setToken, playerData, setPlayerData } = useContext(dataContext);

  function hideModalOperation() {
    setShowErrorSubscriptionModal(false)
  }

  useEffect(() => {
    // let msisdn = null;

    // if (playerData?.id) {
    //   msisdn = playerData?.msisdn;
    // }
    // activityLogSubmission("contacts", msisdn);
    // analyticEvent('contact', 'contact-view')
  }, [])


  return (
    <>
      <Modal show={showErrorSubscriptionModal} centered dialogClassName="modal-dialog" contentClassName="modalContent" onHide={hideModalOperation}>
        <Modal.Header closeButton>
          <Modal.Title><span style={{color:'red'}}>!! দুঃখিত !!</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <span className='modalSubsCustom'> সাবস্ক্রিপশনটি সফল হয়নি।</span>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ErrorSubscriptionModal