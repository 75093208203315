import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { baseUrl, dataContext, portal } from "../../App";
import activityLogSubmission from "../../helper/activitylog";
import styles from "./Subscribe.module.css";
import consentText from './assets/1text.png'
import startBtn from './assets/1.png'
import backBtn from './assets/2.png'
import axios from "axios";
const Subscribe = () => {
  const [clicked, setClicked] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { playerData, token } = useContext(dataContext);
  let navigate = useNavigate();
  function handleStartClicked() {
    window.open("sms://21213?&body=A");
    setClicked(true)
  }
  function handleBackClicked() {
    navigate('../')
  }
  useEffect(() => {
    if (!token) {
      navigate('../', { replace: true })
    }
    if (searchParams.get('qcid')) { sessionStorage.setItem('cid', searchParams.get('qcid')); }
    // setTimeout(() => {
    //   if (!clicked) { handleStartClicked(); }
    // }, 3000);
    let msisdn = null;
    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("subscribe", msisdn);
    axios.post(`${baseUrl}/bl/user_subscription`, {
      portal: portal
    }, {
      headers: { Authorization: `Token ${token}` }
    }).then(res => {handleStartClicked();})
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.status);
        }
      });
  }, []);

  return (
    <>
      <div className={`${styles.landingPage}`}>
        <div className={styles.flexWrapper}>
          <img src={consentText} alt="" srcSet="" className={styles.consentText} />
          <img src={startBtn} onClick={handleStartClicked} alt="" srcSet="" className={styles.consentText} />
          <img src={backBtn} onClick={handleBackClicked} alt="" srcSet="" className={styles.consentText} />
        </div>
      </div>
    </>
  );
};

export default Subscribe;
