import React, { useContext, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import '../../../assets/css/modal.css'
import './TCModal.css'
import { dataContext } from '../../../App';
import activityLogSubmission from '../../../helper/activitylog';
import analyticEvent from '../../../helper/gaEvent';
const TCModal = ({ showTCModal, setShowTCModal,showRefundModal,setShowRefundModal }) => {
  const { token, setToken, playerData, setPlayerData } = useContext(dataContext);
  function hideModalOperation() {
    setShowTCModal(false)
  }

  useEffect(() => {
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("termsConditions", msisdn);
    analyticEvent('termsConditions', 'termsConditions-view')
  }, [])
  return (
    <>
      <Modal show={showTCModal} centered dialogClassName="modal-dialog" contentClassName="modalContent" onHide={hideModalOperation}>
        <Modal.Header closeButton >
          <Modal.Title>শর্তাবলী</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "65vh", overflow: "scroll", display: 'block',overflowX:'hidden' }}>
        <>
          <p>QUIZARD কুইজ গেমে আপনাকে স্বাগতম ! QUIZARD এ কুইজ খেলে দৈনিক, সাপ্তাহিক, টুর্নামেন্ট ভিত্তিক আকর্ষণীয় পুরস্কার জিতে নিন । পাশাপাশি নিজের সাধারণ জ্ঞান ভিত্তিক দক্ষতা ও যাচাই করে ফেলুন । খেলা শুরু করার আগে, অনুগ্রহ করে আপনি নিচের নিয়ম ও শর্তাবলী মনোযোগ সহকারে পড়ুন এবং বুঝুন । এই কুইজ খেলায় অংশগ্রহণ করে, আপনি নিম্নলিখিত / নিন্মক্ত শর্তাবলী মেনে চলতে সম্মত হোন ।
          </p> <br />
          <p> <b>খেলার যোগ্যতা :</b><br />
            QUIZARD কুইজ গেমটি সকল বয়সের এবং জাতীয়তার বাক্তিদের জন্য উন্মুক্ত । কিন্তু, যে কোনো ধরনের প্রতারণা, বাহ্যিক সাহায্যের ব্যবহার, বা কুইজ প্ল্যাটফর্মের সাথে হেরফের করার প্রচেষ্টা অবিলম্বে অযোগ্যতার কারণ হবে। অযোগ্য অংশগ্রহণকারীরা কোনো পুরস্কার বা পুরস্কারের জন্য যোগ্য হবে না। আয়োজক সত্তার কর্মচারী এবং পরিবারের সদস্যরা অংশগ্রহণের যোগ্য নন।
            </p>
            <p> <b>লিংক :</b><br />
              https://www.quizard.live/
            </p>

            <p> <b>কীভাবে যোগদান করবেন :</b><br />
              ব্যবহারকারীরা সাপ্তাহিক ২০ টাকা চার্জে QUIZARD এ যোগদান করতে পারবেন এবং কুইজ খেলতে পারবেন | বিকাশ পেমেন্ট এর মাধ্যমে ব্যবহারকারীরা তাদের অ্যাকাউন্টটির সাবস্ক্রিপশন সম্পন্ন করতে পারবেন ।</p>

            <p> <b>খেলার নিয়ম :</b>
              <br />- QUIZARD কুইজ গেমটিতে ডেইলি কুইজ এর পাশাপাশি একাধিক সেগমেন্টে কুইজ খেলার সুযোগ থাকবে । প্রতিটি সেগমেন্টে একাধিক পছন্দের প্রশ্ন থাকবে এবং দৈনিক ৩ বার খেলা যাবে।
              <br />- প্রতি রাউন্ড এ অংশগ্রহণকারীদের প্রদত্ত বিকল্পগুলি থেকে সঠিক উত্তর নির্বাচন করতে হবে। অংশগ্রহণকারীরা প্রতিটি সঠিক উত্তরের জন্য পয়েন্ট অর্জন করবে।
              <br />- প্রতিটি প্রশ্নের কাঠিন্য অনুযায়ী উত্তর জমা দেওয়ার জন্য একটি নির্দিষ্ট সময়সীমা থাকবে। নির্ধারিত সময়ের মধ্যে উত্তর দিতে ব্যর্থ হলে সেই নির্দিষ্ট প্রশ্নের জন্য অযোগ্য ঘোষণা করা হবে।
              <br />- গেমের শুরুতে স্কোরিং সিস্টেম প্রদান করা হবে এবং প্রশ্নের জটিলতার উপর নির্ভর করে এটি পরিবর্তিত হতে পারে।
              <br />- তিনটি রাউন্ড এর মধ্যে যে রাউন্ড এর স্কোর বেশি হবে, সেটিই ফাইনাল স্কোর হিসেবে গণনা করা হবে ।
              <br />- তুলনামূলক কম সময়ে সর্বাধিক সঠিক উত্তর প্রদানকারী দের মধ্যে মেধাক্রম অনুসারে প্রথম ১০ জন কে পুরস্কৃত করা হবে । </p>


            <p> <b>বিজয়ীদের পুরস্কার  :</b>
              <br /> QUIZARD কর্তৃপক্ষ বিজয়ীদের ২ ধরনের পুরস্কার প্রদান করে থাকে ।
              <br /> - দৈনিক পুরস্কার : প্রতিদিনের অংশগ্রহণকারীদের মধ্যে সেরা ১০ জন সঠিক উত্তর প্রদানকারী কে ৫০ টাকা পর্যন্ত বিকাশ ক্যাশ ইন করা হবে |
              <br /> - টুর্নামেন্ট ভিত্তিক পুরস্কার : প্রতিটি টুর্নামেন্ট শেষে সেরা ৩ জন পাবেন ১০ হাজার টাকার সমতুল্য মেগা পুরস্কার - গ্যাজেট অথবা গিফট কার্ড (মেধাক্রম অনুসারে)। 

              <br /> ** একজন প্রতিযোগী প্রতি সপ্তাহে সর্বোচ্চ ২০০ টাকা পর্যন্ত বিকাশ ক্যাশ ইন পাওয়ার যোগ্যতা রাখেন ।  

              <br /> ** আয়োজক সত্তা তার বিবেচনার ভিত্তিতে পুরস্কার পরিবর্তন বা প্রতিস্থাপন করার অধিকার সংরক্ষণ করে।</p>


            <p> <b>টুর্নামেন্ট এর বিজয়ী হবার নিয়মাবলী :</b><br />
            প্রতিটি টুর্নামেন্ট শেষে অংশগ্রহণকারীদের টোটাল স্কোর ও সময় গণনা করা হবে । সর্বনিম্ন সময়ে সর্বোচ্চ স্কোর ধারী ৩ জন থেকে ১০ জন কে মেগা পুরস্কার প্রদান করা হবে । টুর্নামেন্ট এ বিজয়ী হওয়ার জন্য টুর্নামেন্ট চলাকালীন সময় একজন অংশগ্রহণকারী QUIZARD থেকে আনসাবস্ক্রাইব করতে পারবে না । অন্যথায় সর্বোচ্চ স্কোর থাকলেও অযোগ্য হিসেবে ধরা হবে এবং কোনো পুরস্কারের জন্য যোগ্য বিবেচিত হবে না। </p>

            <p> <b>ক্যাম্পেইন এর সময়সীমা :</b><br />
              প্রতি বৃহস্পতিবার থেকে বুধবার পর্যন্ত ।</p>

            <p> <b>গোপনীয়তা :</b><br />
              কুইজের সময় সংগৃহীত অংশগ্রহণকারীদের তথ্য শুধুমাত্র খেলা পরিচালনার উদ্দেশ্যে এবং প্রযোজ্য হলে পুরস্কার প্রদানের উদ্দেশ্যে ব্যবহার করা হবে। ব্যক্তিগত তথ্য পূর্ব সম্মতি ছাড়া তৃতীয় পক্ষের সাথে ভাগ করা হবে না।
            </p>
            <p> <b>বুদ্ধিবৃত্তিক সম্পত্তি :</b><br />
              প্রশ্ন, উত্তর এবং ছবি সহ সমস্ত কুইজের বিষয়বস্তু হল আয়োজক সত্তার বৌদ্ধিক সম্পত্তি। অননুমোদিত প্রজনন বা বিতরণ নিষিদ্ধ।
            </p>
            <p> <b>দায় :</b><br />
              কুইজের সময় ঘটতে পারে এমন কোনও প্রযুক্তিগত সমস্যা, বাধা বা ত্রুটির জন্য আয়োজক সত্তা দায়বদ্ধ নয়। অংশগ্রহণকারীরা যেন তাদের নিজস্ব ঝুঁকিতে খেলে।
            </p>
            <p> <b>আনসাবস্ক্রাইব করার নিয়ম :</b><br />
            আনসাবস্ক্রাইব করতে ক্লিক করুন।
            </p>
            <p>
            {(playerData?.isSubscribe) && <button className="refundBtn" onClick={() => { setShowRefundModal(true); setShowTCModal(false) }}>&nbsp;
            Unsubscribe</button>}
            </p>
            </>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TCModal