import { useSwipeable } from "react-swipeable";

const LeaderBoardSelection = ({ events, activeBoard, setActiveBoard }) => {
  const handlers = useSwipeable({
    onSwiped: (eventData) => updateSwipeActiveBoard(eventData.deltaX),
    delta: 10,
    preventScrollOnSwipe: false,
    trackTouch: true,
    trackMouse: true,
    rotationAngle: 0,
    swipeDuration: Infinity,
    touchEventOptions: { passive: true },
  });
  function updateSwipeActiveBoard(changeInX) {
    if (events?.length < 2) {
      return;
    }
    let setPosition = null;
    events.forEach((event, index) => {
      if (event?.id === activeBoard) setPosition = index;
    });
    if (changeInX < 5) {
      if (setPosition > 0) {
        setActiveBoard(events[--setPosition]?.id)
      }
    }
    if (changeInX > -5) {
      if (setPosition < (events.length - 1)) {
        setActiveBoard(events[++setPosition]?.id)
      }
    }
  }
  return (<>
    <div className="leaderBoardSelection" {...handlers}>
      {events.map((singleEvent) => {
        return (
          <div
            key={singleEvent.id}
            className={`leaderboardBtn ${activeBoard === singleEvent?.id ? "activeLeader" : ""
              }`}
            onClick={() => {
              setActiveBoard(singleEvent?.id);
            }}
          >
            {singleEvent?.events}
          </div>
        );
      })}
    </div>
  </>)
}

export { LeaderBoardSelection }