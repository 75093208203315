import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import "../../assets/css/modal.css";
import "./LoginModalCampaign.css";
import axios from "axios";
import { baseUrl, portal, dataContext } from "../../App";
import activityLogSubmission from "../../helper/activitylog";
import { useNavigate, useSearchParams } from "react-router-dom";
import analyticEvent from "../../helper/gaEvent";

const LoginModalCampaign = ({
  showLoginModalCampaign,
  setShowLoginModalCampaign,
  activeBottom,
  setActiveBottom,
}) => {
  const { token, setToken, playerData, setPlayerData } =
    useContext(dataContext);
  const [mobileNumber, setMobileNumber] = useState("");
  const [showErrorOutline, setShowErrorOutline] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  let navigate = useNavigate();
  useEffect(() => {
    analyticEvent('login', 'login-view')
  }, [])
  if (activeBottom === "campaign") {
    sessionStorage.setItem("AuthToken", "");
    sessionStorage.setItem("user_msisdn", "");
  }
  function checkMobileNumber() {
    var mobilePattern = /^(?:\+88|88)?(01[3-9]\d{8})$/;
    if (mobileNumber.match(mobilePattern)) {
      return true;
    } else {
      return false;
    }
  }
  function loginOperation() {
    if (checkMobileNumber()) {
      setIsLoading(true);
      axios
        .post(`${baseUrl}/api/login/`, {
          msisdn: mobileNumber.toString().slice(-10),
          portal: portal,
        })
        .then((response) => {
          // console.log(response.data.token);
          let newToken = response.data.token;
          sessionStorage.setItem("AuthToken", newToken);
          sessionStorage.setItem("user_msisdn", mobileNumber.toString().slice(-10));
          setToken(newToken);
          analyticEvent('login', 'login-success')
          if (activeBottom === "campaign") {
            if (response.data.isSubscribe) {
              navigate("../");
            } else {
              activityLogSubmission(
                "msisdn_submit",
                mobileNumber.toString().slice(-10)
              );
              const config = {
                headers: { Authorization: `Token ${token}` },
              };
              axios
                .post(
                  `${baseUrl}/wordly/subscription/create/`,
                  { portal: portal, qcid: null, "service_type": "Wordly" },
                  config
                )
                .then((response) => {
                  let data = response.data;
                  if (data?.redirectURL) {
                    console.log(`${data?.redirectURL}`);
                    window.location.replace(`${data?.redirectURL}`);
                  }
                });
            }
          } else {
            setShowLoginModalCampaign(false);
            setIsLoading(false);
          }
        });
    } else {
      console.log("error");
      setShowErrorOutline(true);
      analyticEvent('login', 'login-error')
    }
  }

  function hideModalOperation() {
    setShowLoginModalCampaign(false);
    if (activeBottom === "profile") {
      if (!playerData?.id) {
        setActiveBottom("home");
      }
    }
    if (activeBottom === "campaign") {
      navigate("../");
    }
  }
  return (
    <>
      <Modal
        show={showLoginModalCampaign}
        // centered
        dialogClassName="campaign-login-modal-dialog"
        contentClassName="modalContent"
        onHide={hideModalOperation}
        backdrop="static"
        backdropClassName='backdropOpacity'
      >
        <Modal.Header closeButton>
          <Modal.Title>আপনার মোবাইল নম্বর দিন</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className={`inputSpan ${showErrorOutline && "errorOutline"}`}>
            +88{" "}
            <input
              type="tel"
              maxLength={11}
              onKeyUp={(e) => {
                setMobileNumber(e.target.value);
                setShowErrorOutline(false);
              }}
              id="mobileNumber"
              name="mobileNumber"
              placeholder="মোবাইল নম্বর"
            />{" "}
          </span>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn numberSubmit"
            onClick={loginOperation}
          >
            {isLoading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              "প্রবেশ করুন"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LoginModalCampaign;
